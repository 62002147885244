@import url("https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  /* background: #0c0f13; */
  background-color: rgb(0, 0, 0);
  /* background: rgb(131,122,72);
  background: linear-gradient(135deg, rgba(131,122,72,1) 0%, rgb(63, 58, 37) 5%, rgb(38, 30, 1) 19%, rgba(23,18,1,1) 32%, rgba(22,20,23,1) 51%); */
  overflow: hidden;
  font-family: "Sulphur Point", sans-serif;
  color: #8b8b8b;
  font-size: 0.9rem;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #8b8b8b;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
}

.bottom-left {
  padding: 1.5rem;
  transform: translate3d(0, -100%, 0) !important;
}

.center-quota{
  transform: translate3d(0, -100%, 0) !important;
  width: 520px;
  font-size: 32px;
}

.center-quota-mobile{
  transform: translate3d(0, -100%, 0) !important;
  width: 320px;
  font-size: 24px;
}


.canvas > div {
  z-index: 10000000;
}

#root > div > div {
  pointer-events: none;
}

@media screen and (min-width: 53em) {
  body {
    overflow: hidden;
  }
  .frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    pointer-events: none;
    align-content: space-between;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title links"
      "... ..."
      "... nav";
  }
  .frame__title {
    grid-area: title;
  }
  .frame__links {
    grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__nav {
    grid-area: nav;
    justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
 
  
}
